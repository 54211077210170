export const SET_USER_DETAIL = "SET_USER_DETAIL";
export const SET_LANGUAGE_LIST = "SET_LANGUAGE_LIST";
export const CHANGE_UUID = "CHANGE_UUID";
export const RELOAD_PAGE = "RELOAD_PAGE";
export const SET_SELECTED_NEARME = "SET_SELECTED_NEARME";
export const RESET_SELECTED_NEARME = "RESET_SELECTED_NEARME";
export const SELECTED_LANGUAGE = "SELECTED_LANGUAGE";
export const TOUR_GUIDE = "TOUR_GUIDE";
export const CLONING_FLAG = "CLONING_FLAG";
export const Feedback_Mod = "Feedback_Mod";
export const SETFILTER_FLAG = "SETFILTER_FLAG";
export const SETFILTER_DATA = "SETFILTER_DATA";
export const APPLY_SURVEY_FILTER = "APPLY_SURVEY_FILTER";
