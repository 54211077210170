// export const apiUrl = 'https://dev.nextgen.imonitorplus.com/nextgendevservice/v1.0';
// export const baseUrl = 'https://dev.nextgen.imonitorplus.com';

// export const apiUrl = "https://v1.nextgen.imonitorplus.com/service/v1.0/";
// export const baseUrl = "https://v1.nextgen.imonitorplus.com";

// export const apiUrl = "https://v1.nextgen.imonitorplus.com/uatservice/v1.0/";
// export const baseUrl = "https://v1.nextgen.imonitorplus.com";

// export const apiUrl = "https://ng.imonitorplus.com/service/v1.0/";
// export const baseUrl = "https://ng.imonitorplus.com";

export const apiUrl = "https://ncdpakistan.engageall.org/service_ncd/v1.0/";
export const baseUrl = "https://ncdpakistan.engageall.org";

// export const apiUrl = "https://ncdpakistan.engageall.org/service_ncd/v1.0/";
// export const baseUrl = "https://ncdpakistan.engageall.org/";

// https://d670-103-252-7-235.ngrok-free.app/

// export const apiUrl = "https://uatv1.imonitorplus.com/uatservice/v1.0/";
// export const baseUrl = "https://uatv1.imonitorplus.com";

export const redisApiUrl = "https://uatv1.imonitorplus.com/service_redis/v1.0/";
//export const redisApiUrl = "https://6e0b-103-252-7-235.in.ngrok.io/service/v1.0/";

// export const apiUrl = "https://ng.imonitorplus.com/service/v1.0/";
// export const baseUrl = "https://ng.imonitorplus.com";

//export const apiUrl = "https://qa.nextgen.imonitorplus.com/qaservice/v1.0/";
//export const baseUrl = "https://qa.nextgen.imonitorplus.com";

//export const apiUrl = 'https://uat.nextgen.imonitorplus.com/uatservice/v1.0/';
//export const baseUrl = 'https://uat.nextgen.imonitorplus.com';

//export const apiUrl = 'https://mzoneimpact.myfirewall.co:3443/service/v1.0/';
//export const baseUrl = 'https://mzoneimpact.myfirewall.co:3443';

// export const apiUrl = "https://stoptbpartnershiponeimpact.org/service/v1.0/";
// export const baseUrl = "https://stoptbpartnershiponeimpact.org";

export const loginUrl = "community/dashboardLogin";
export const registerUrl = "community/registerCommunity";
export const communityRegistration = "community/register";
export const setupDetails = "community/setupDetails";
export const saveCommunityDetails = "community/saveCommunitySetup";
export const languageList = "community/masterLanguageList";
export const getCommunityWiseLanguages = "community/getCommunityWiseLanguages";
export const communityTypeList = "community/communityMasterTypeList";
export const getCaseListForInbox = "/caseSetForService/getCaseListForInbox";
export const CaseDetailUrl = "/caseSetForService/getCaseDetailsForService";
export const updateCaseUrl = "/caseSetForService/updateCaseForService";
export const changeCasestatusUrl =
  "/caseSetForService/ChangeCaseStatusForService";
export const dashboardStatUrl = "/caseSetForService/getDashboardStats";
export const lineListUrl = "/user/getApplicantByFilter";
export const linelistprovinceUrl = "/user/getProvinceDistrictList";
export const mapListUrl = "/caseSetForService/getCaseListForMap";
export const trendGraphUrl = "/caseSetForService/getGraphStatsCountUserWise";
export const getServiceAnalysisByCommunityId =
  "/caseSetForService/getServiceAnalysisByCommunityId";
export const dashboardServiceInfoUrl =
  "/caseSetForService/getServiceInfoForDashboardUsingCommunityId";
export const downloadPdfReportByCaseIdUrl =
  "/caseSetForService/downloadPdfReportByCaseId";
export const addService = "/getInvolved/addService";
export const getServiceByCommunityId = "getInvolved/getServiceByCommunityId";
export const getQuestionsByServiceId = "getInvolved/getQuestionsByServiceId";
export const casereportsWithComments =
  "reportGeneration/downloadExcelReportForCasesWithComments";
export const casereportsWithWithoutComments =
  "reportGeneration/downloadExcelReportForCasesWithoutComments";
export const getDashboardConfigurationByCommunityId =
  "community/getDashboardConfigurationByCommunityId";
export const getCommunityDetails = "community/getCommunityDetails";
export const getMasterLangugageList = "community/getMasterLangugageList";
export const updateCommunity = "community/updateCommunity";
export const getMasterCommunityTypeList = "master/getMasterCommunityTypeList";
export const getNearmeByCommunityId = "/nearme/getNearmeByCommunityId";
export const getNearmeListByServiceQuestionId =
  "/nearme/getNearmeListByServiceQuestionId";
export const getRoleWiseContent = "cms/getRoleWiseContent";
export const getContentForDashboard = "cms/getContentForDashboard";
export const addContentFromDashboard = "cms/addContentFromDashboard";
export const updateContentFromDashboard = "cms/updateContentFromDashboard";
export const checkIfChildPresent = "cms/checkIfChildPresent";
export const deleteContentFromDashboard = "cms/deleteContentFromDashboard";
export const changeSequenceForContent = "cms/changeSequenceForContent";
export const getTypesByCommunityId = "/nearme/getTypesByCommunityId";
export const addNearme = "/nearme/addNearme";
export const updateNearme = "/nearme/updateNearme";
export const downloadExcel = "/nearme/downloadExcel";
export const uploadExcel = "/nearme/uploadExcel";
export const addNearmeRating = "/nearme/addNearmeRating";
export const getTranslationsByCommunityIdForRegisteredLanguages =
  "community/getTranslationsByCommunityIdForRegisteredLanguages";
export const getTranslationsByCommunityId =
  "community/getTranslationsByCommunityId";
export const addTranslationsByCommunityId =
  "community/addTranslationsByCommunityId";
export const deleteLabelFromTranslationsByCommunityId =
  "community/deleteLabelFromTranslationsByCommunityId";
export const getStaticContent = "cms/getStaticContent";
export const getFacilityList = "user/getFacilityList";
export const getFilerByLocationForCommunityId =
  "caseSetForService/getFilerByLocationForCommunityId";
export const addServiceQuestion = "/getInvolved/addServiceQuestion";
export const editServiceQuestion = "/getInvolved/editServiceQuestion";
export const deleteService = "/getInvolved/deleteService";
export const getServiceById = "/getInvolved/getServiceById";
export const editService = "/getInvolved/editService";
export const deleteServiceQuestion = "/getInvolved/deleteServiceQuestion";
export const deleteServiceDependentQuestion =
  "/getInvolved/deleteServiceDependentQuestion";
export const addServiceDependentQuestion =
  "/getInvolved/addServiceDependentQuestion";
export const rearrangeServices = "/getInvolved/rearrangeServices";
export const rearrangeServiceQuestions =
  "/getInvolved/rearrangeServiceQuestions";
export const copyContentToAnotherLanguage = "/cms/copyContentToAnotherLanguage";
export const getServiceQuestionsById = "getInvolved/getServiceQuestionsById";
export const getSurveyByCommunityId = "survey/getSurveyByCommunityId";
export const getQuestionsBySurveyId = "survey/getQuestionsBySurveyId";
export const addSurvey = "survey/addSurvey";
export const getSurveyById = "survey/getSurveyById";
export const editSurvey = "survey/editSurvey";
export const rearrangeSurveys = "survey/rearrangeSurveys";
export const deleteSurvey = "survey/deleteSurvey";
export const getSectionBySurveyId = "survey/getSectionBySurveyId";
export const addSectionAndSectionQuestion =
  "survey/addSectionAndSectionQuestion";
export const getSurveySectionQuestionsBySectionId =
  "survey/getSurveySectionQuestionsBySectionId";
export const getSectionById = "survey/getSectionById";
export const editSection = "survey/editSection";
export const deleteSurveySection = "survey/deleteSurveySection";
export const getSurveySectionQuestionsById =
  "survey/getSurveySectionQuestionsById";
export const addSurveySectionQuestion = "survey/addSurveySectionQuestion";
export const editSectionQuestion = "survey/editSectionQuestion";
export const deleteSurveySectionDependentQuestion =
  "survey/deleteSurveySectionDependentQuestion";
export const addSurveyDependentQuestions = "survey/addSurveyDependentQuestions";
export const rearrangeSurveySectionQuestions =
  "survey/rearrangeSurveySectionQuestions";
export const getSurveyQuestionsForDashboard =
  "survey/getSurveyQuestionsForDashboard";
export const deleteSurveySectionQuestion = "survey/deleteSurveySectionQuestion";
export const getSurveyQuestionById = "survey/getSurveyQuestionById";
export const editSurveyDependentQuestions =
  "survey/editSurveyDependentQuestions";
export const getCommunitywiseRoleList = "roleAuth/getCommunitywiseRoleList";
export const getApplicantByCommunityId = "user/getApplicantByCommunityId";
export const addUserForCommunity = "user/addUserForCommunity";
export const updateUserRole = "user/updateUserRole";
export const getMasterContentTypeList = "master/getMasterContentTypeList";
export const activeInactiveContent = "cms/activeInactiveContent";
export const updateContentListByContentId = "cms/updateContentListByContentId";
export const contentListByCommunityId = "cms/contentListByCommunityId";
export const getComponentList = "roleAuth/getComponentList";
export const inactiveUser = "user/inactiveUser";
export const addComponent = "roleAuth/addComponent";
export const delComponent = "roleAuth/deleteComponent";
export const updateComponent = "roleAuth/updateComponent";
export const changeComponentSequence = "roleAuth/changeComponentSequence";
export const getMasterRoleTypeList = "master/getMasterRoleTypeList";
export const getRoleDetails = "roleAuth/getRoleDetails";
export const addRole = "roleAuth/addRole";
export const updateRole = "roleAuth/updateRole";
export const communityListForCloning = "community/communityListForCloning";
export const CloningDataListByCommunityId =
  "community/CloningDataListByCommunityId";
export const disableModeratorForAllForumByCommunityId =
  "getConnected/disableModeratorForAllForums";
export const cloneCommunityData = "community/cloneCommunityData";
export const getCommunityBrandingByCommunityId =
  "community/getCommunityBrandingByCommunityId";
export const updateCommunityBranding = "community/updateCommunityBranding";
export const getForumChatGroupByCommunityId =
  "getConnected/getForumChatGroupByCommunityId";
export const addForumChatGroup = "getConnected/addForumChatGroup";
export const getUsersForForumChatByCommunityId =
  "getConnected/getUsersForForumChatByCommunityId";
export const getForumChatGroupUsersByForumChatId =
  "getConnected/getForumChatGroupUsersByForumChatId";
export const updateForumChatGroup = "getConnected/updateForumChatGroup";
export const addNearmeType = "nearme/addNearmeType";
export const updateNearmeType = "nearme/updateNearmeType";
export const getMasterChannelTypeList = "master/getMasterChannelTypeList";
export const getChannelListByCommunityId =
  "community/getChannelListByCommunityId";
export const addChannelsForCommunityId = "community/addChannelsForCommunityId";
export const uploadResolutionProtocolExcel =
  "reportGeneration/uploadResolutionProtocolExcel";
export const downloadResolutionProtocolExcel =
  "reportGeneration/downloadResolutionProtocolExcel";
export const registrationQuestionFilterList =
  "caseSetForService/registrationQuestionFilterList";
export const getMasterConfiguarationTimeList =
  "master/getMasterConfiguarationTimeList";
export const setConfiguarationForService =
  "getInvolved/setConfiguarationForService";
export const getCommunityListForUser = "user/getCommunityListForUser";
export const forgotPassword = "user/forgotPassword";
export const resetPassword = "user/resetPassword";
export const getConfiguredPageDetails =
  "/dynamicDashboard/getConfiguredPageDetails";
export const registrationWise = "cms/getStaticContent";
export const serviceList = "getInvolved/getServiceByCommunityId";
export const configurePage = "dynamicDashboard/configurePage";
export const getMasterViewTypeList = "master/getMasterViewTypeList";
export const getcontentListByCommunityId = "cms/contentListByCommunityId";
export const getdefineViewTypeForContentList =
  "community/defineViewTypeForContentList";
export const getSurveyCountAnanlysis =
  "dynamicDashboard/getSurveyCountAnanlysis";
export const getUserCountForDashboard = "user/getUserCountForDashboard";
export const getServiceCountAnanlysis =
  "dynamicDashboard/getServiceCountAnanlysis";
export const addPhrasesForVoiceBot = "voicebot/addPhrasesForVoiceBot";
export const getPhrasesForVoiceBot = "voicebot/getPhrasesForVoiceBot";
export const updatePhrasesForVoiceBot = "voicebot/updatePhrasesForVoiceBot";
export const saveCommunityChannelConfiguration =
  "community/saveCommunityChannelConfiguration";
export const getCommunityChannelConfiguration =
  "community/getCommunityChannelConfiguration";
export const getRegistrationQuestionsForDashboard =
  "registration/getRegistrationQuestionsForDashboard";
export const getRegistrationQuestions = "registration/getRegistrationQuestions";
export const addRegistrationQuestion = "registration/addRegistrationQuestion";
export const getRegistrationQuestionsByQuestionId =
  "registration/getRegistrationQuestionsByQuestionId";
export const getRegistrationQuestionsById =
  "registration/getRegistrationQuestionsById";
export const editRegistrationQuestions =
  "registration/editRegistrationQuestions";
export const addRegistrationDependentQuestions =
  "registration/addRegistrationDependentQuestions";
export const editRegistrationDependentQuestions =
  "registration/editRegistrationDependentQuestions";
export const inactiveRegistrationQuestion =
  "registration/inactiveRegistrationQuestion";
export const inactiveRegistrationDependentQuestion =
  "registration/inactiveRegistrationDependentQuestion";
export const rearrangeRegistrationQuestions =
  "registration/rearrangeRegistrationQuestions";
export const cloneMultipleRegistrationQuestions =
  "/registration/cloneMultipleRegistrationQuestions";
export const getQuestionsByServiceIdForDashboard =
  "getInvolved/getQuestionsByServiceIdForDashboard";
export const getQuestionByIdForDashboard =
  "getInvolved/getQuestionByIdForDashboard";
export const editServiceDependentQuestions =
  "getInvolved/editServiceDependentQuestions";
export const cloneMultipleServices = "getInvolved/cloneMultipleServices";
export const cloneServiceQuestions = "getInvolved/cloneServiceQuestions";
export const cloneSurveyQuestions = "survey/cloneSurveyQuestions";
export const cloneMultipleSurvey = "survey/cloneMultipleSurvey";
export const getNearmeLocationFilterForCloneByCommunityId =
  "nearme/getNearmeLocationFilterForCloneByCommunityId";
export const getNearmeForCloneByCommunityId =
  "nearme/getNearmeForCloneByCommunityId";
export const cloneNearmeDataForMultipleCommunityId =
  "nearme/cloneNearmeDataForMultipleCommunityId";
export const addCalenderRemainderForUser = "user/addCalenderRemainderForUser";
export const getCalenderRemaindersForUser = "user/getCalenderRemaindersForUser";
export const updateCalenderRemainderForUser =
  "user/updateCalenderRemainderForUser";
export const callSurvey = "/callSurvey";
export const getCalenderRemainderRegistrationForDashboard =
  "registration/getCalenderRemainderRegistrationForDashboard";
export const editCalenderRemainderRegistrationQuestions =
  "registration/editCalenderRemainderRegistrationQuestions";
export const addCalenderRemainderRegistrationQuestion =
  "registration/addCalenderRemainderRegistrationQuestion";
export const cloneMultipleCommunityContent =
  "cms/cloneMultipleCommunityContent";
export const cloneAdditionalContents = "cms/cloneAdditionalContents";
export const getCalenderRemainderRegistrationQuestionsById =
  "registration/getCalenderRemainderRegistrationQuestionsById";
export const getSurveyInfoForDashboardUsingCommunityId =
  "caseSetSurvey/getSurveyInfoForDashboardUsingCommunityId";
export const getGraphStats = "caseSetSurvey/getGraphStats";
export const getAvatarByCommunityId = "getConnected/getAvatarByCommunityId";
export const addAvatarByCommunityId = "getConnected/addAvatarByCommunityId";
export const updateAvatarByCommunityId =
  "getConnected/updateAvatarByCommunityId";
export const getDashboardChartInfoUsingCommunityId =
  "caseSetSurvey/getDashboardChartInfoUsingCommunityId";
export const getDashboardChartInfoForServiceUsingCommunityId =
  "caseSetForService/getDashboardChartInfoForServiceUsingCommunityId";
export const getSurveyAnalysisByCommunityId =
  "caseSetSurvey/getSurveyAnalysisByCommunityId";
export const getQuestionsByServiceIdForAllLanguage =
  "getInvolved/getQuestionsByServiceIdForAllLanguage";
export const getQuestionsBySurveyAndSectionIdForAllLanguage =
  "survey/getQuestionsBySurveyAndSectionIdForAllLanguage";
export const getRegistrationQuestionsForAllLanguage =
  "registration/getRegistrationQuestionsForAllLanguage";
export const showRegistrationContentList = "cms/showRegistrationContentList";
export const getMasterTemplateList = "master/getMasterTemplateList";
export const saveCommunityTemplateList = "community/saveCommunityTemplateList";
export const getTemplatesByCommunityId = "community/getTemplatesByCommunityId";
export const rasaUserList = "rasabot/rasaUserList";
export const userChatDetail = "rasabot/userChatDetails";
export const ReportGeneration = "rasabot/ReportGeneration";
export const chatAnalysis = "rasabot/chatAnalysis";
export const notification = "community/notification";
export const broadcastUploadSurveys = "surveyUpload/broadcastUploadSurveys";
export const addCommunityCaseSupport =
  "caseSetForService/addCommunityCaseSupport";
export const getMasterCategoryList = "master/getMasterCategoryList";
export const getMasterSupportList = "master/getMasterSupportList";
export const getCommunitySupportList = "community/getCommunitySupportList";
export const getCommunityCategoryList = "community/getCommunityCategoryList";
export const saveCommunityCategoryList = "community/saveCommunityCategoryList";
export const saveCommunitySupportList = "community/saveCommunitySupportList";
export const getCaseListForDashboard =
  "caseSetForService/getCaseListForDashboard";
export const getUsersByCommunityId = "user/getUsersByCommunityId";
export const archiveService = "getInvolved/archiveServices";
export const archiveRegistrationQuestions =
  "registration/archiveRegistrationQuestions";
export const archiveSurveys = "survey/archiveSurveys";
export const addMasterMedicine = "treatment/addMasterMedicine";
export const getMasterMedicineList = "treatment/getMasterMedicineList";
export const updateMasterMedicine = "treatment/updateMasterMedicine";
export const addTreatmentConfiguration = "treatment/addTreatmentConfiguration";
export const getTreatmentList = "treatment/getTreatmentList";
export const updateTreatmentConfiguration =
  "treatment/updateTreatmentConfiguration";
export const addRegiment = "treatment/addRegimen";
export const getRegimentList = "treatment/getRegimenList";
export const updateRegiment = "treatment/updateRegimen";
export const mapRegimentCommunity = "treatment/mapRegimenCommunity";
export const getRegimentsByCommunityId = "treatment/getRegimensByCommunityId";
export const addQuestionsToCommunity = "treatment/addQuestionsToCommunity";
export const getUsersForForumGroupByCommunityId =
  "getConnected/getUsersForForumGroupByCommunityId";
export const getCommunityAndBranding = "community/getCommunityAndBranding";
export const getLanguageAndMasterCommunityTypeList =
  "master/getLanguageAndMasterCommunityTypeList";
export const getDashboardChartInfoUsingCommunity =
  "caseSetSurvey/getDashboardChartInfoUsingCommunityId";
export const getDashboardChartInfoUsingCommunityForService =
  "caseSetForService/getDashboardChartInfoForServiceUsingCommunityId";
export const getUserRegistrationList = "user/getUserRegistrationList";
export const getOverviewContent = "community/getOverviewContent";
export const getDashboardChartsData = "rasabot/getDashboardChartsData";
export const getBubbleDashboardChartsData =
  "rasabot/getBubbleDashboardChartsData";
export const getWeeklyDashboardChartData =
  "rasabot/getWeeklyDashboardChartData";
export const linkServiceQuestion = "getInvolved/linkServiceQuestion";
export const getServiceQuestionLinkingDetails =
  "getInvolved/getServiceQuestionLinkingDetails";
export const addCommunityQuestionsBasedOnCategory =
  "registration/addCommunityQuestionsBasedOnCategory";
export const getCommunityCategoryQuestions =
  "registration/getCommunityCategoryQuestions";
export const getCommunityCategoryQuestionsById =
  "registration/getCommunityCategoryQuestionsById";
export const editCommunityQuestionByCategory =
  "registration/editCommunityQuestionByCategory";
export const fireBaseDicussionBoard =
  "https://react-firebase-chat-4a2c6.firebaseio.com/";
export const getCommunityLatLong = "community/getCommunityLatLong";
export const getMasterPageList = "master/getMasterPageList";
export const addConfiguration = "community/addConfiguration";
export const getConfiguration = "community/getConfiguration";
export const feedBackmech =
  "https://debug.duretechnologies.com/openIssue_nextGen.php";
export const getCommunitySectionLinking =
  "community/getCommunitySectionLinking";
export const addCommunitySectionLinking =
  "community/addCommunitySectionLinking";
export const updateResponderNotifications =
  "community/updateResponderNotifications";
export const getMasterProviderList = "master/getMasterProviderList";
export const getResponderNotificationFlag =
  "/community/getResponderNotificationFlag";
export const assignResponderToCaseId =
  "/caseSetForService/assignResponderToCaseId";
export const broadcastNotificationsviaSocialMediaChannels =
  "/community/broadcastNotificationsviaSocialMediaChannels";
export const cmsNotificationsviaSocialMediaChannels =
  "cms/cmsNotificationsviaSocialMediaChannels";
export const communityNearmeConfiguartion =
  "nearme/communityNearmeConfiguartion";
export const getCommunityNearmeConfiguration =
  "nearme/getCommunityNearmeConfiguration";
export const getAssistedModelFlag = "community/getAssistedModelFlag";
export const getCommunityRegions = "community/getCommunityRegions";
export const getMongochartsConfiguration =
  "mongocharts/getMongochartsConfiguration";
export const addMongoChartConfiguration =
  "mongocharts/addMongoChartConfiguration";
export const updateMongoChartConfiguration =
  "mongocharts/updateMongoChartConfiguration";
export const getApplicantByFilter = "user/getApplicantByFilter";
export const getMapStatsForDashboard =
  "caseSetForService/getMapStatsForDashboard";
export const getCountOfServiceByNearmeId = "nearme/getCountOfServiceByNearmeId";
export const getCasesInfoByUserRegistration =
  "caseSetForService/getCasesInfoByUserRegistration";
export const getUserRegistrationAnalysisByChannelType =
  "user/getUserRegistrationAnalysisByChannelType";
export const getNearmeWiseCasesCount = "nearme/getNearmeWiseCasesCount";
export const getDashboardOverviewStats =
  "getInvolved/getDashboardOverviewStats";
export const getNearmeFacilityStats = "nearme/getNearmeFacilityStats";
export const downloadPdfReportByUserId =
  "caseSetForService/downloadPdfReportByUserId";
export const getMasterCaseStatusList = "master/getMasterCaseStatusList";
export const addMasterCaseStatus = "master/addMasterCaseStatus";
export const getKnowledgableStats = "cms/getKnowledgableStats";
export const getCaseStatusesForCommunity =
  "caseSetForService/getCaseStatusesForCommunity";
export const saveCaseStatusForCommunity =
  "caseSetForService/saveCaseStatusForCommunity";
export const downloadCasePdfReportByFacilityId =
  "caseSetForService/downloadCasePdfReportByFacilityId";
export const uploadFile = "nearme/uploadFile";
export const downloadFile = "nearme/downloadFile";
export const CommunityLineListExcelGeneration =
  "user/CommunityLineListExcelGeneration";

export const FacilityLineListExcelGeneration =
  "nearme/FacilityLineListExcelGeneration";
export const getCommunityEvidenceCaptureFlags =
  "community/getCommunityEvidenceCaptureFlags";
export const setCommunityEvidenceCaptureFlags =
  "community/setCommunityEvidenceCaptureFlags";
export const getGlobalFiltersForCommunity =
  "community/getGlobalFiltersForCommunity";
export const getDashboardChartInfoUsingCommunityIdWithGlobalFilters =
  "caseSetSurvey/getDashboardChartInfoUsingCommunityIdWithGlobalFilters";
export const getServiceAnalysisByCommunityIdWithGlobalFilters =
  "/caseSetForService/getServiceAnalysisByCommunityIdWithGlobalFilters";
export const getOverviewContentWithGlobalFilters =
  "/community/getOverviewContentWithGlobalFilters";
export const getNearmeListByServiceQuestionIdWithGlobalFilters =
  "nearme/getNearmeListByServiceQuestionIdWithGlobalFilters";
export const getNearmeWiseCasesCountWithGlobalFilters =
  "nearme/getNearmeWiseCasesCountWithGlobalFilters";
export const getCasesInfoByUserRegistrationWithGlobalFilters =
  "caseSetForService/getCasesInfoByUserRegistrationWithGlobalFilters";
export const getUserLocationByServiceQuestionId =
  "nearme/getUserLocationByServiceQuestionId";
export const getCaseListForInboxWithGlobalFilters =
  "caseSetForService/getCaseListForInboxWithGlobalFilters";
export const getCountOfServiceByNearmeIdWithGlobalFilters =
  "nearme/getCountOfServiceByNearmeIdWithGlobalFilters";
export const getUserRegistrationInfoByUserId =
  "/user/getUserRegistrationInfoByUserId";
export const getWeeklyDashboardChartDataChannelWise =
  "rasabot/getWeeklyDashboardChartDataChannelWise";
export const getServiceEvidenceCaptureFlags =
  "getInvolved/getServiceEvidenceCaptureFlags";
export const setConfiguarationForSurvey = "survey/setConfiguarationForSurvey";
export const getQuestionsForAllService =
  "getInvolved/getQuestionsForAllService";
export const setCommunityForCloning = "community/setCommunityForCloning";
export const getUserLocationByServiceQuestionIdWithGlobalFilters =
  "nearme/getUserLocationByServiceQuestionIdWithGlobalFilters";
export const addOptionLabelMap = "getInvolved/addOptionLabelMap";
export const getOptionLabelList = "getInvolved/getOptionLabelList";
export const getJiraFeeedbackForCommunity =
  "community/getJiraFeeedbackForCommunity";
export const setJiraFeeedbackForCommunity =
  "community/setJiraFeeedbackForCommunity";
export const raiseJiraIssueForCommunity =
  "community/raiseJiraIssueForCommunity";
export const setJiraFeeedbackAttachmnetForCommunity =
  "community/setJiraFeeedbackAttachmnetForCommunity";
export const getModeratorCount = "getConnected/getModeratorCount";
export const getOperationalOverviewContent =
  "community/getOperationalOverviewContent";
export const sendNotificationForContentUpdate =
  "cms/sendNotificationForContentUpdate";
export const getDashboardChartInfoForServiceUsingCommunityIdWithGlobalFilters =
  "caseSetForService/getDashboardChartInfoForServiceUsingCommunityIdWithGlobalFilters";
export const getSurveyAnalysisByCommunityIdWithGlobalFilters =
  "caseSetSurvey/getSurveyAnalysisByCommunityIdWithGlobalFilters";
export const getUploadDetails = "nearme/getUploadDetails";
export const addSurveyWeightageLegendsRequest =
  "survey/addSurveyWeightageLegends";
export const getSurveyWeightageLegends = "survey/getSurveyWeightageLegends";
export const getSurveyScoreFlagForCommunity =
  "caseSetSurvey/getSurveyScoreFlagForCommunity";

export const serviceExcelGeneration =
  "caseSetForService/serviceExcelGeneration";
export const surveyExcelGeneration = "caseSetSurvey/surveyExcelGeneration";
export const unblockUserForCommunity = "user/unblockUserForCommunity";
export const getSampleSurveyExcel = "survey/getSampleSurveyExcel";
export const broadcastBotMessage = "surveyUpload/broadcastBotMessage";
export const addUpdateServerConfiguration =
  "community/addUpdateServerConfiguration";
export const getServerConfigurationByCommunityId =
  "community/getServerConfigurationByCommunityId";
export const addUpdatePinnedCmsData = "cms/addUpdatePinnedCmsData";
export const getDayWiseUsersandReportedUser =
  "rasabot/getDayWiseUsersandReportedUser";
export const getSurveyIndicatorData = "caseSetSurvey/getSurveyIndicatorData";
export const getServiceIndicatorData =
  "caseSetForService/getServiceIndicatorData";
export const getLastActive30Days = "user/getLastActive30Days";
export const getSurveyAnalyticsData = "caseSetSurvey/getSurveyAnalyticsData";
export const getMontlyModuleWiseUsageData =
  "rasabot/getMontlyModuleWiseUsageData";
export const getDemographicProfileContent =
  "community/getDemographicProfileContent";

export const getMasterCountryList = "master/getMasterCountryList";
export const getMasterProvinceByCountryId =
  "master/getMasterProvinceByCountryId";
export const getMasterDistrictByCountryIdAndProvinceId =
  "master/getMasterDistrictByCountryIdAndProvinceId";

export const getMasterLocationDataByCountryIds =
  "master/getMasterLocationDataByCountryIds";

export const getServiceAnalyticsData =
  "caseSetForService/getServiceAnalyticsData";

export const downloadExcelForBulkUpload =
  "caseSetForService/downloadExcelForBulkUpload";
export const bulkUploadUserServiceDataByExcel =
  "caseSetForService/bulkUploadUserServiceDataByExcel";
export const approveUser = "user/approveUser";
export const declineUser = "user/declineUser";

export const getOrganizationForCommunity = "master/getOrganizationForCommunity";
export const addOrganizationForCommunity = "master/addOrganizationForCommunity";
export const updateOrganizationForCommunity =
  "master/updateOrganizationForCommunity";
export const getMasterCountryByCommunityIDandLevelIdandIso =
  "master/getMasterCountryByCommunityIDandLevelIdandIso";
export const getMasterProvinceByCommunityIDandLevelIdandIso =
  "master/getMasterProvinceByCommunityIDandLevelIdandIso";
export const getPdfOneImpactJson = "caseSetForService/getPdfOneImpactJson";
export const getMasterDistrictCommunitywise =
  "master/getMasterDistrictCommunitywise";
export const getMasterLocationDataUsingCommunityId =
  "master/getMasterLocationDataUsingCommunityId";
export const getPDFDataByCommunityId = "community/getPDFDataByCommunityId";
export const getIndicatorWiseDemographicsByCommunityId =
  "community/getIndicatorWiseDemographicsByCommunityId";
export const getGenderUserTypeAndTPT = "/community/getGenderUserTypeAndTPT";
export const getIndicatorQuestionMapping =
  "caseSetForService/getIndicatorQuestionMapping";
export const addUpdateIndicatorQuestionMapping =
  "caseSetForService/addUpdateIndicatorQuestionMapping";
export const getMasterIndicatorMapping =
  "caseSetForService/getMasterIndicatorMapping";

export const getServiceUserDataChannelWise =
  "caseSetForService/getServiceUserDataChannelWise";
export const getUniqueUserDataChannelWise = "user/getUniqueUserDataChannelWise";
export const updatePinnedCmsData = "getConnected/updatePinById";
export const getCallingData =
  "https://firestore.googleapis.com/v1/projects/cihr-881cd/databases/(default)/documents/0af5b9b6bd80899082419998cb3bf18b/";

// export const addReminder = "/v1uatqtzservice/reminder/addReminder"
export const usageTime = "https://cihr-881cd-default-rtdb.firebaseio.com"; // Need to change this api need to call through api
export const getUserIndicatorQuestionMapping =
  "user/getUserIndicatorQuestionMapping";
export const addUpdateUserIndicatorQuestionMapping =
  "user/addUpdateUserIndicatorQuestionMapping";
export const updateCaseSetForService =
  "caseSetForService/updateCaseSetForService";
export const getIndicatorsDistrictWise = "community/getIndicatorsDistrictWise";
export const getFaciltyWiseCounter = "community/getFaciltyWiseCounter";
export const getPinnedCmsData = "cms/getPinnedCmsData";
export const addAutomatedRemainderWithQuestionnaire =
  "community/addAutomatedRemainderWithQuestionnaire";
export const getAutomatedRemainderWithQuestionnaire =
  "community/getAutomatedRemainderWithQuestionnaire";
export const updateAutomatedRemainderWithQuestionnaire =
  "/community/updateAutomatedRemainderWithQuestionnaire";
export const excelForUserSurvey = "/reportGeneration/excelForUserSurvey";
export const getTreatmentAdherenceSurveyData =
  "/caseSetSurvey/getTreatmentAdherenceSurveyData";
export const getCihrUserTrendChart = "/user/getCihrUserTrendChart";
export const addReminder = "/v1uatqtzservice/reminder/addReminder";
// export const addReminder = "/v1liveqtz/reminder/addRemin
export const getSocialIndicator = "community/getSocialIndicator";

// export const getTreatmentAdherenceTrendChartData =
//   "caseSetSurvey/getTreatmentAdherenceTrendChartData";

export const getTreatmentAdherenceTrendChartData = "user/getTrendChartSummary";

export const getCihrUserDataTable = "/user/getCihrUserDataTable";
export const getParentChildQuestionIds =
  "caseSetSurvey/getParentChildQuestionIds";

export const updateRedisTranslationData =
  "community/updateRedisTranslationData";
export const updateRedisMasterServiceData =
  "/getInvolved/updateRedisMasterServiceData";
export const updateRedisMasterSurveyData = "survey/updateRedisMasterSurveyData";
export const getReportedUserList = "/user/getReportedUserList";
export const postClearCache = "/community/removePDFDataFromCacheByCommunityId";
export const syncSurveyExcelGeneration =
  "caseSetSurvey/syncSurveyExcelGeneration";
export const createObjectURL = (obj) => {
  return URL.createObjectURL(obj);
};

export const revokeObjectURL = (obj) => {
  return URL.revokeObjectURL(obj);
};
